
















import { Component, Mixins, Prop } from 'vue-property-decorator'

import HintAnswer from '@/components/_uikit/HintAnswer.vue'
import QuestionIndicator from '@/components/_uikit/QuestionIndicator.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TestQuestionResultMixin from '@/mixins/TestQuestionResultMixin'
import { EducationLargeTaskTestQuestionResource, TaskQuestionType, TaskRateType } from '@/store/types'

@Component({
  components: {
    HintAnswer,
    QuestionIndicator,
    TextInput,
  },
})
export default class QuestionResult extends Mixins(TestQuestionResultMixin) {
  @Prop({ required: true })
  protected question!: EducationLargeTaskTestQuestionResource

  private get hasAnswer () {
    return this.question.rateType.value !== TaskRateType.NO_RIGHT || this.questionNoAnswer
  }

  private get questionNoAnswer() {
    return this.question.type.value !== TaskQuestionType.NO_ANSWER
  }
}
