


















import { Component, Prop, Vue } from 'vue-property-decorator'

import { EducationLargeTaskTestQuestionResource, NameValueResource } from '@/store/types'
import { alphabet, alphabetEng } from '@/utils/constants'

@Component
export default class QuestionRatioView extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskTestQuestionResource

  @Prop({ required: true })
  private subject!: NameValueResource

  private get isAllEmptyAnswers() {
    return this.question.answers.every(answer => answer.text)
  }

  private get isAllEmptySequences() {
    return this.question.sequences.every(sequence => sequence.text)
  }

  private alphabet = this.subject.value === 9 ? alphabetEng : alphabet
}
