













import { Component, Mixins, Prop } from 'vue-property-decorator'

import HintAnswer from '@/components/_uikit/HintAnswer.vue'
import QuestionIndicator from '@/components/_uikit/QuestionIndicator.vue'
import TestQuestionResultMixin from '@/mixins/TestQuestionResultMixin'
import { EducationLargeTaskTestQuestionResource } from '@/store/types'

@Component({
  components: {
    HintAnswer,
    QuestionIndicator,
  },
})
export default class QuestionSingleResult extends Mixins(TestQuestionResultMixin) {
  @Prop({ required: true })
  protected question!: EducationLargeTaskTestQuestionResource
}
