





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class QuestionIndicator extends Vue {
  @Prop({ default: false })
  private correct!: boolean

  @Prop({ default: false })
  private partial!: boolean

  @Prop({ default: false })
  private missed!: boolean

  @Prop({ default: false })
  private empty!: boolean
}
