








import { Component, Mixins, Prop } from 'vue-property-decorator'

import TestQuestionResultMixin from '@/mixins/TestQuestionResultMixin'
import { EducationLargeTaskTestQuestionResource } from '@/store/types'

@Component
export default class QuestionSequenceView extends Mixins(TestQuestionResultMixin) {
  @Prop({ required: true })
  protected question!: EducationLargeTaskTestQuestionResource
}
